import { LogOut, NavArrowDown, Settings } from 'iconoir-react';

import React, { useState } from 'react';

import { ChangePasswordTemplate } from '../../../password/templates/ChangePassword';

import { DropMenu } from '../../atoms/DropMenu';
import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import { useAuthContext } from '../../../authentication/core/context/Authentication.context';
import styles from './styles.module.scss';
import { ITopBarProps } from './types';

const TopBar: React.FC<ITopBarProps> = ({ title }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout, authState } = useAuthContext();
  const userName = authState?.user_name || 'Admin';
  const neighborhoodName = authState?.roles[0].neighborhood_name || '';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    {
      label: 'Cambiar contraseña',
      onClick: () => {
        setIsModalOpen(true);
      },
      icon: (
        <Icon color={'black'} width={20} height={20}>
          <Settings />
        </Icon>
      ),
    },
    {
      label: 'Cerrar sesión',
      onClick: () => logout(),
      icon: (
        <Icon color={'black'} width={20} height={20}>
          <LogOut />
        </Icon>
      ),
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <Text
          text={neighborhoodName ? neighborhoodName + ' - ' + title : title}
          textStyle={'SH1-Bold'}
          className={styles.title}
          color='primary'
        />
        <div className={styles.userContainer}>
          <div onClick={toggleMenu}>
            <Text text={userName} textStyle={'SH2-Regular'} />
            <div
              className={`${styles.icon} ${isMenuOpen ? styles.iconOpen : ''}`}
            >
              <Icon color={'black'} width={24} height={24} strokeWidth={1.5}>
                <NavArrowDown />
              </Icon>
            </div>
          </div>
          <div className={styles.menu}>
            <DropMenu items={menuItems} isOpen={isMenuOpen} />
          </div>
        </div>
      </div>
      <ChangePasswordTemplate
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export { TopBar };
