import { AxiosResponse } from 'axios';

import { useCallback } from 'react';

import { useAxiosInstance } from '../../../../utils/axios';

import { CreateNewsEntity } from '../entity/CreateNews.entity';
import { NewsUseCase } from '../usecase/News.usecase';

export const useNewsService = (): NewsUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getNews = useCallback(async (): Promise<AxiosResponse> => {
    return await axiosInstance().get(`/admin/neighborhood/news`);
  }, [axiosInstance]);

  const createNews = useCallback(
    async (data: CreateNewsEntity): Promise<AxiosResponse> => {
      return await axiosInstance().post('/admin/neighborhood/news', data);
    },
    [axiosInstance],
  );

  return {
    getNews,
    createNews,
  };
};
