import React, { useContext } from 'react';

import { PackageReportTable } from '../../../../feature/packageReport/templates/PackageReportTable';

import { TopBar } from '../../../../feature/ui/molecules/TopBar';

import { SidebarContext } from '../../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import styles from './styles.module.scss';

const PackagesTemplate = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      {' '}
      <TopBar title='Reporte de paquetes' />
      <div className={styles.content}>
        <PackageReportTable />
      </div>
    </div>
  );
};

export default PackagesTemplate;
