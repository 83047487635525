import { Attachment } from 'iconoir-react';

import React from 'react';

import { Icon } from '../../../ui/atoms/Icon';
import Text from '../../../ui/atoms/Text';

import styles from './styles.module.scss';
import { INewsDetailsProps } from './types';

const NewsDetails: React.FC<INewsDetailsProps> = ({ news }) => {
  const handleImageClick = () => {
    window.open(news.image, '_blank');
  };

  return (
    <div className={styles.newsDetails}>
      <div className={styles.newsTextHeader}>
        <div className={styles.text}>
          <Text text={news.category} textStyle='B3-Regular' color='primary' />
          <Text
            text={news.title}
            textStyle='SH2-Bold'
            color='primary'
            className={styles.title}
          />
          <div className={styles.description}>
            <Text text={news.description} textStyle='B2-Regular' />
          </div>
        </div>
      </div>
      {news.image && (
        <div className={styles.imageContainer}>
          <div className={styles.title}>
            <Icon color='primary' width={13} height={13}>
              <Attachment />
            </Icon>
            <Text
              text='Foto de portada adjunta'
              textStyle='B2-Bold'
              color='primary'
            />
          </div>
          <div className={styles.image}>
            <img
              src={news.image}
              alt={news.title}
              className={styles.newsImage}
              onClick={handleImageClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { NewsDetails };
