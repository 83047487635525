import { Post, SquareCursor } from 'iconoir-react';

import React, { useEffect, useState } from 'react';

import { Button } from '../../../ui/atoms/Button';
import Dropdown from '../../../ui/atoms/Dropdown';
import { Icon } from '../../../ui/atoms/Icon';
import { Input } from '../../../ui/atoms/Input';
import Text from '../../../ui/atoms/Text';

import { Popup } from '../../../ui/molecules/Popup';

import { useAuthContext } from '../../../authentication/core/context/Authentication.context';
import { NewsCategory } from '../../core/enums/NewsCategory.enum';
import { useCreateNews } from '../../core/hooks/CreateNews.hook';
import styles from './styles.module.scss';

interface NewsFormProps {
  isLoading?: boolean;
}

const NewsForm: React.FC<NewsFormProps> = () => {
  const { authState } = useAuthContext();
  const neighborhoodName = authState?.roles[0].neighborhood_name || '';
  const [handleCreateNews, loading, successCreation] = useCreateNews();
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: '',
    image: '',
  });
  const [errors, setErrors] = useState({
    title: '',
    content: '',
    category: '',
  });

  useEffect(() => {
    if (successCreation) {
      setIsSuccessPopupOpen(true);
      setFormData({
        title: '',
        content: '',
        category: '',
        image: '',
      });
    }
  }, [successCreation]);

  const handleChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const handleCreateNewsSubmit = async () => {
    const newErrors = {
      title: formData.title ? '' : 'Este campo es requerido',
      content: formData.content ? '' : 'Este campo es requerido',
      category: formData.category ? '' : 'Este campo es requerido',
    };

    setErrors(newErrors);

    if (!formData.title || !formData.content || !formData.category) {
      return;
    }

    await handleCreateNews(formData);
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
    window.location.reload();
  };

  const categoryOptions = Object.values(NewsCategory).map((value) => ({
    value,
    label: value,
  }));

  return (
    <div className={styles.addCard}>
      <div className={styles.title}>
        <Icon color='primary'>
          <Post height={24} width={24} />
        </Icon>
        <div className={styles.titleText}>
          <Text text={'Nueva noticia'} textStyle={'SH2-Bold'} color='primary' />
          <Text text={neighborhoodName} textStyle={'B3-Bold'} color='primary' />
        </div>
      </div>
      <div className={styles.formContainer}>
        <Input
          label='Título *'
          placeholder='Ingresa el título...'
          value={formData.title}
          onChangeText={(value) => handleChange('title', value)}
          maxLength={50}
          modernStyle
          error={errors.title}
          style={styles.input}
        />
        <div className={styles.body}>
          <Text
            text={'Cuerpo de la noticia *'}
            textStyle={'B2-Medium'}
            color='black'
          />
          <textarea
            value={formData.content}
            onChange={(e) => handleChange('content', e.target.value)}
            placeholder='Ingresa tu mensaje...'
            className={
              errors.content === ''
                ? styles.textarea
                : styles.textarea + ' ' + styles.errorBorder
            }
            maxLength={2500}
          />
          {errors.content && (
            <Text
              text={errors.content}
              textStyle={'B2-Medium'}
              color='primary'
            />
          )}
        </div>
        <Dropdown
          label='Categoría *'
          options={categoryOptions}
          selectedValue={formData.category}
          onValueChange={(value) => handleChange('category', value)}
          error={errors.category}
        />
        <Input
          label='Agregar portada'
          placeholder='Ingresa el link de la imagen...'
          value={formData.image}
          onChangeText={(value) => handleChange('image', value)}
          modernStyle
          maxLength={200}
          style={styles.input}
        />
        <Button
          text='Publicar noticia'
          textStyle='B2-Medium'
          onPress={handleCreateNewsSubmit}
          icon={
            <Icon color='neutral' height={18} width={18}>
              <SquareCursor />
            </Icon>
          }
          cancelButton
          isLoading={loading}
          style={styles.button}
        />
      </div>
      <Popup
        isOpen={isSuccessPopupOpen}
        onClose={handleCloseSuccessPopup}
        imageSrc={'resiAnounce'}
        width={250}
        titleText='¡Noticia publicada correctamente!'
        buttons={false}
      />
    </div>
  );
};

export { NewsForm };
