import React, { useContext } from 'react';

import { NotificationList } from '../../../../feature/notifications/templates/NotificationList';

import { TopBar } from '../../../../feature/ui/molecules/TopBar';

import { SidebarContext } from '../../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import styles from './styles.module.scss';

const NotificationsTemplate = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      <TopBar title='Notificaciones' />
      <div className={styles.content}>
        <NotificationList />
      </div>
    </div>
  );
};

export default NotificationsTemplate;
