import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PageNotFound from '../../native/pages/Errors/404-PageNotFound';
import Guards from '../../native/pages/Guards';
import GuardsCompany from '../../native/pages/Guards/Company';
import Login from '../../native/pages/Login';
import Neighborhood from '../../native/pages/Neighborhood';
import News from '../../native/pages/News';
import Notifications from '../../native/pages/News/Notifications';
import ForgotPassword from '../../native/pages/Password';
import CheckEmail from '../../native/pages/Password/CheckEmail';
import ResetPassword from '../../native/pages/Password/ResetPswd';
import Pqrs from '../../native/pages/Pqrs';
import PrivateLayout from '../../native/pages/PrivateLayout';
import PackagesReport from '../../native/pages/Reports/Packages';
import VisitsReport from '../../native/pages/Reports/Visits';
import Residents from '../../native/pages/Residents';
import ResidentsRequests from '../../native/pages/Residents/Requests';

import { useAuthContext } from '../../feature/authentication/core/context/Authentication.context';
import { LocalStorageVault } from '../LocalStorageVault';
import { decryptData } from '../encryption';

const AppNavigation = () => {
  const { authState, setAuthState } = useAuthContext();

  useEffect(() => {
    const vault = new LocalStorageVault();

    vault
      .getValueFromStorage('auth')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((userInfo: boolean | any) => {
        if (userInfo) {
          const userInformation = decryptData(userInfo);
          if (userInformation) setAuthState(JSON.parse(userInformation));
        }
      })
      .catch((err: unknown) => {
        console.error(err);
      });
  }, [setAuthState]);

  return (
    <Routes>
      {authState ? (
        <Route element={<PrivateLayout />}>
          <Route path='/' element={<Navigate to='/neighborhood' />} />
          <Route path='/neighborhood' element={<Neighborhood />} />
          <Route path='/guards' element={<Guards />} />
          <Route path='/guards/company' element={<GuardsCompany />} />
          <Route path='/residents' element={<Residents />} />
          <Route path='/residents/requests' element={<ResidentsRequests />} />
          <Route path='/news' element={<News />} />
          <Route path='/news/notifications' element={<Notifications />} />
          <Route path='/pqrs' element={<Pqrs />} />
          <Route path='/reports/visits' element={<VisitsReport />} />
          <Route path='/reports/packages' element={<PackagesReport />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
      ) : (
        <>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Login />} />
          <Route path='/forgot' element={<ForgotPassword />} />
          <Route path='/check-email' element={<CheckEmail />} />
          <Route path='/account/reset' element={<ResetPassword />} />
          <Route path='*' element={<PageNotFound />} />
        </>
      )}
    </Routes>
  );
};

export { AppNavigation };
