import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { PasswordService } from '../service/Password.service';

export const ChangePassword = () => {
  const { changePswd } = PasswordService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);

  const handleChangePassword = useCallback(
    async (
      email: string,
      oldPswd: string,
      newPswd: string,
      onSuccessfully: () => void,
    ) => {
      setLoading(true);
      await handleErrors(
        async () => {
          await changePswd(email, oldPswd, newPswd);
          toast.success('Contraseña cambiada exitosamente!');

          onSuccessfully();
        },
        async () => {
          toast.error('Datos incorrectos.');
        },
        () => {
          setLoading(false);
        },
      );
    },
    [changePswd, handleErrors],
  );

  return { handleChangePassword, loading };
};
