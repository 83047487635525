import { UserPlus } from 'iconoir-react';

// import { XmarkCircleSolid } from 'iconoir-react';
import React, { useContext, useState } from 'react';

import AddResidencePopup from '../../../feature/neighborhood/templates/AddResidencePopup';
import { HomeCards } from '../../../feature/neighborhood/templates/HomeCards';

import { Button } from '../../../feature/ui/atoms/Button';
import { Icon } from '../../../feature/ui/atoms/Icon';
import { Loading } from '../../../feature/ui/atoms/Loading';
import { SearchInput } from '../../../feature/ui/atoms/SearchInput';

// import { Banner } from '../../../feature/ui/molecules/Banner';
import { TopBar } from '../../../feature/ui/molecules/TopBar';

import { SidebarContext } from '../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import { useGetBlocks } from '../../../feature/neighborhood/core/hooks/GetBlocks.hook';
import { useHomes } from '../../../feature/neighborhood/core/hooks/Homes.hook';
import styles from './styles.module.scss';

const NeighborhoodTemplate = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  const [searchValue, setSearchValue] = useState('');
  const [homes, loading] = useHomes();
  // const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [isPopupOpenAddHome, setIsPopupOpenAddHome] = useState(false);
  const [fetchBlocks, blocksList, groupingType] = useGetBlocks();

  const filteredHomes = homes
    ? homes.filter(
        (home) =>
          home.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          home.block.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : [];

  const handleOpenPopupAddHome = async () => {
    await fetchBlocks();
    setIsPopupOpenAddHome(true);
  };

  const handleClosePopupAddHome = () => {
    setIsPopupOpenAddHome(false);
  };

  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      <TopBar title='Mi conjunto' />
      <div className={styles.content}>
        {/* // TODO: Implement Banner
        {isBannerVisible && (
          <Banner
            icon={{
              color: 'tertiary3',
              strokeWidth: 2,
              height: 24,
              width: 24,
              children: <XmarkCircleSolid />,
              onClick: () => setIsBannerVisible(false),
            }}
            image={{
              srcName: 'finishAlert',
              width: 'auto',
              height: '3.5vw',
            }}
            title={{
              text: 'Continúa donde lo dejaste',
              textStyle: 'SH2-Regular',
            }}
            subtitle={{
              text: 'Para acceder a todas las funcionalidades y una mejor experiencia, por favor sigue configurando las residencias de Aqua Living. ¡Estamos aquí para ayudarte a completar este proceso!',
              textStyle: 'B2-Regular',
            }}
            textColor='black'
            firstOption={{
              text: 'Recordar luego',
              textStyle: 'B2-Regular',
              onClick: () => alert('recordar clicked'),
              color: 'secondary2',
            }}
            secondOption={{
              text: 'Continuar',
              textStyle: 'B2-Bold',
              onClick: () => alert('continuar clicked'),
              color: 'primary',
            }}
          />
        )} */}
        <div className={styles.topContainer}>
          <div className={styles.button}>
            <Button
              text='Añadir residencia'
              icon={
                <Icon color='neutral' height={22} width={22}>
                  <UserPlus />
                </Icon>
              }
              color='primary'
              onPress={handleOpenPopupAddHome}
              cancelButton={true}
            />
          </div>
          <div className={styles.searchInput}>
            <SearchInput
              placeholder='Buscar por torres o apartamentos...'
              value={searchValue}
              onChangeText={setSearchValue}
            />
          </div>
        </div>
        {loading ? (
          <Loading size='small' backgroundColor={true} />
        ) : (
          <HomeCards homes={filteredHomes} />
        )}
      </div>
      {blocksList && (
        <AddResidencePopup
          isOpen={isPopupOpenAddHome}
          onClose={handleClosePopupAddHome}
          blocksList={blocksList}
          groupingType={groupingType}
        />
      )}
    </div>
  );
};

export { NeighborhoodTemplate };
